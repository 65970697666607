import { PleaseWait } from '../../common/PleaseWait';
import { Container, Typography } from '@mui/material';
import { Card } from '../../common/Card';

export const Dashboard: React.FC = () => {
  const isLoading = false;

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading ? (
          <PleaseWait />
        ) : (
          <>
            <Typography>Helló portál!</Typography>
          </>
        )}
      </Card>
    </Container>
  );
};
