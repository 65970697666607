import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Access } from '../../../access/Access';
import { PleaseWait } from '../../common/PleaseWait';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Card } from '../../common/Card';
import { addMessage } from '../../../slices/applicationSlice';
import { Vehicle } from '../../../models/vehicles/Vehicle';
import { selectVehicles, setResponse, setVehicles } from '../../../slices/vehiclesSlice';
import { LoadVehiclesResponse } from '../../../models/vehicles/LoadVehiclesResponse';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import EditIcon from '@mui/icons-material/Edit';
import { EditVehicle } from './EditVehicle';
import AddIcon from '@mui/icons-material/Add';
import { SaveVehicleRequest } from '../../../models/vehicles/SaveVehicleRequest';
import { SaveVehicleResponse } from '../../../models/vehicles/SaveVehicleResponse';
import RefreshIcon from '@mui/icons-material/Refresh';
import { VehicleValues } from './EditVehicle';
import { isAdminRole } from '../../../slices/userSlice';
import { Status } from './Status';
import DeleteIcon from '@mui/icons-material/Delete';
import { AlertDialog } from '../../common/AlertDialog';
import { DeleteVehicleCriteria } from '../../../models/vehicles/DeleteVehicleCriteria';
import { useFormat } from '../../../utility/useFormat';

export const Vehicles: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const vehicles: Vehicle[] | undefined | null = useSelector(selectVehicles);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);
  const [showOnlyActive, setShowOnlyActive] = useState<boolean>(true);
  const [vehicleToDelete, setVehicleToDelete] = useState<Vehicle | undefined>(undefined);

  const { formatNumber } = useFormat();

  useEffect(() => {
    if (vehicles === undefined && !isLoading) {
      setIsLoading(true);
      dispatch(setVehicles(null));
      const access = new Access();
      access
        .loadVehicles({ getAllVehicles: {} })
        .then((value: LoadVehiclesResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'make',
      isNumeric: false,
      label: 'Márka',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'model',
      isNumeric: false,
      label: 'Modell',
      disablePadding: true,
      width: '20%',
    },

    {
      id: 'licensePlate',
      isNumeric: false,
      label: 'Rendszám',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'loadWeight',
      isNumeric: false,
      label: 'Terhelhetőség (kg)',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'active',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const saveVehicle = (values: VehicleValues) => {
    const access = new Access();
    const vehicleCriteria: Vehicle = {
      ...values,
    };
    const request: SaveVehicleRequest = {
      saveVehicle: vehicleCriteria,
    };

    access
      .saveVehicle(request)
      .then((value: SaveVehicleResponse | undefined) => {
        if (value?.error == null) {
          dispatch(setVehicles(undefined));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-vehicle' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setSelectedVehicle(undefined);
      });
  };

  const handleCreateVehicle = (): void => {
    setSelectedVehicle({
      id: 0,
      model: '',
      brand: '',
      licensePlate: '',
      loadWeight: 0,
      active: true,
    });
  };

  const handleChangeSearchTerm = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const refreshList = () => dispatch(setVehicles(undefined));

  const deleteVehicle = (): void => {
    if (vehicleToDelete != null) {
      const access = new Access();
      const deleteVehicleCriteria: DeleteVehicleCriteria = {
        id: vehicleToDelete.id,
      };
      const request: SaveVehicleRequest = {
        deleteVehicle: deleteVehicleCriteria,
      };

      access
        .saveVehicle(request)
        .then((value: SaveVehicleResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setVehicles(undefined));
            dispatch(addMessage({ message: 'Törölve', severity: 'success', key: 'save-vehicle' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setVehicleToDelete(undefined);
        });
    }
  };

  const vehiclesData: TableRowData[] = useMemo(
    () => {
      const term = searchTerm ? searchTerm.toLowerCase() : undefined;
      return (vehicles ?? [])
        .filter(
          (vehicle: Vehicle) =>
            (!showOnlyActive || vehicle.active) &&
            (!term ||
              vehicle.brand == null ||
              vehicle.brand.toLowerCase().indexOf(term) > -1 ||
              vehicle.model == null ||
              vehicle.model.toLowerCase().indexOf(term) > -1 ||
              vehicle.licensePlate == null ||
              vehicle.licensePlate.toLowerCase().indexOf(term) > -1 ||
              vehicle.loadWeight == null ||
              vehicle.loadWeight.toString() === term)
        )
        .map((vehicle: Vehicle) => ({
          rowId: `vehicle-${vehicle.id}`,
          id: vehicle.id,
          columns: [
            {
              id: 'brand',
              cValue: vehicle.brand,
              value: vehicle.brand,
            },
            {
              id: 'model',
              cValue: vehicle.model,
              value: vehicle.model,
            },
            {
              id: 'licensePlate',
              cValue: vehicle.licensePlate,
              value: vehicle.licensePlate,
            },
            {
              id: 'loadWeight',
              cValue: vehicle.loadWeight,
              value: formatNumber(vehicle.loadWeight),
            },
            {
              id: 'active',
              cValue: vehicle.active,
              value: <Status data={vehicle.active} />,
            },
            {
              id: 'operation',
              cValue: '',
              value: (
                <>
                  <Button
                    startIcon={<EditIcon />}
                    color="primary"
                    size="small"
                    onClick={() => setSelectedVehicle(vehicle)}
                  >
                    Szerkeszt
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    color="primary"
                    size="small"
                    onClick={() => setVehicleToDelete(vehicle)}
                  >
                    Töröl
                  </Button>
                </>
              ),
            },
          ],
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicles, showOnlyActive, searchTerm]
  );

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {vehicles === undefined ? (
              <Alert severity="warning">Nincenek járművek</Alert>
            ) : (
              <>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{ my: 2 }}
                    startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                    color="primary"
                    onClick={handleCreateVehicle}
                  >
                    Új jármű
                  </Button>
                  <IconButton sx={{ my: 2 }} color="primary" onClick={refreshList} title="Frissítés">
                    <RefreshIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-end', mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} />}
                    label="Csak aktívak mutatása"
                  />
                  <TextField
                    id="search-term-tasks"
                    label="Szűrés"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    size="small"
                    sx={{ flexGrow: 1, ml: 2 }}
                  />
                </Box>
                <Table
                  id="vehicles"
                  headCells={headCells}
                  rows={vehiclesData}
                  hideOthersOnSelect={true}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 25 }}
                />
                {selectedVehicle && (
                  <EditVehicle
                    data={selectedVehicle}
                    onClose={() => setSelectedVehicle(undefined)}
                    onSave={saveVehicle}
                  />
                )}

                <AlertDialog
                  title="Biztos benne?"
                  agreeBtnText="Törlés"
                  disagreeBtnText="Mégse"
                  content={
                    <Typography variant="body1">
                      Ez a jármű véglegesen törlésre kerül, bíztosan törölni szeretné?
                    </Typography>
                  }
                  id="delete-vehicle"
                  onCancel={() => setVehicleToDelete(undefined)}
                  onAgree={deleteVehicle}
                  openModal={vehicleToDelete != null}
                  agreeBtnColor="error"
                />
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
