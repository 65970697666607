import { ReactElement } from 'react';
import { Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  data: boolean;
}

export const getStatus = (status: boolean): { label: string; color: any; icon: ReactElement } | undefined => {
  if (status == null) return undefined;
  switch (status) {
    case true:
      return {
        label: 'Aktív',
        color: 'success',
        icon: <DoneIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    default:
      return { label: 'Inaktív', color: 'default', icon: <CloseIcon /> };
  }
};

export const Status: React.FC<Props> = ({ data }: Props) => {
  const status = getStatus(data);
  return status ? (
    <Chip
      size="small"
      avatar={status.icon}
      sx={{ my: 0.3, minWidth: 105 }}
      color={status.color}
      label={status.label}
      onDelete={undefined}
    />
  ) : null;
};
