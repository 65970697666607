import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
  title?: string;
  content: any;
  className?: string;
  openModal?: boolean;
  id: string;
  disagreeBtnText: string;
  agreeBtnText: string;
  onAgree?: any;
  onCancel?: any;
  agreeBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}
export const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  id,
  openModal,
  content,
  disagreeBtnText,
  agreeBtnText,
  className,
  onAgree = () => {},
  onCancel = () => {},
  agreeBtnColor = 'primary',
}: AlertDialogProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (agree: boolean) => {
    if (agree) {
      onAgree();
    } else {
      onCancel();
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(!!openModal);
  }, [openModal]);

  return (
    <div>
      <Dialog
        open={open}
        id={id}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={className}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {content}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <Button onClick={() => handleClose(false)} color="primary" id={`${id}-disagree`}>
            {disagreeBtnText}
          </Button>
          <Button
            onClick={() => handleClose(true)}
            color={agreeBtnColor}
            autoFocus
            id={`${id}-agree`}
            variant="contained"
          >
            {agreeBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
