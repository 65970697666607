import { createTheme } from '@mui/material/styles';
import { Chart } from 'chart.js';
import { isNaN } from 'formik';

declare module '@mui/material/styles' {
  interface CustomTheme {
    layout: {
      appBar: {
        backgroundColor: string;
        color: string;
      };
      mainColor: string;
    };
  }

  interface Theme extends CustomTheme {}

  interface ThemeOptions extends CustomTheme {}
}

export const materialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  layout: {
    appBar: {
      backgroundColor: '#36393F',
      color: '#fff',
    },
    mainColor: '#B1B3B4',
  },
  spacing: 6,
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#36393F',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'white',
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: 'rgba(0, 0, 0, 0.38) !important',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@keyframes bounceIn': {
          '0%': {
            opacity: 1,
            transform: 'scale(0.9)',
          },

          '30%': {
            opacity: 1,
            transform: 'scale(1.05)',
          },

          '50%': {
            opacity: 1,
            transform: 'scale(0.9)',
          },

          '70%': {
            opacity: 1,
            transform: 'scale(1)',
          },

          '100%': {
            opacity: 1,
            transform: 'scale(0.9)',
          },
        },
        '@keyframes background-wave': {
          '0%': {
            transform: 'translateX(-100%)',
          },
          '60%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(100%)',
          },
        },
        //'@font-face': [orpheus],
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
        },
        textPrimary: {
          color: '#36393F',
        },
        containedSecondary: {
          backgroundColor: 'rgba(0, 0, 0, 0.54)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.54) !important',
          },
        },
        // containedPrimary: {
        //   minWidth: '120px',
        //   backgroundColor: '#000000',
        //   '&:hover': {
        //     backgroundColor: '#000000 !important',
        //   },
        // },
        outlinedPrimary: {
          minWidth: '120px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          color: '#000000',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&:not(.MuiInputLabel-root)': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.error': {
            color: '#f44336',
          },
        },
        contained: {
          marginLeft: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          textTransform: 'capitalize',
          fontWeight: 600,
        },
        h3: {
          fontSize: '2rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6)',
          width: '0.8em',
          height: '0.8em',
          '&.custom-icon': {
            width: 'unset',
            height: 'unset',
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          //paddingLeft: '32px',
          padding: '6px 12px',
          // padding: '8px 16px',
          borderBottom: 'solid 2px #f3f3f3',
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        button: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#36393F',
            '& span': {
              fontWeight: 500,
            },
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#e8eaf6',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            marginTop: '8px',
          },
        },
        outlined: {
          select: {
            padding: '12px 12px',
          },
        },
      },
    },
  },
  // overrides: {
  //   MuiPickersToolbar: {
  //     toolbar: {
  //       backgroundColor: '#000',
  //       '& .MuiPickersToolbarText-toolbarTxt': {
  //         color: '#fff',
  //       },
  //     },
  //   },
  // },
});

const numberFormatter = new Intl.NumberFormat('hu-HU', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

Chart.defaults.set('plugins.datalabels', {
  color: '#FFF',
  formatter: (c: any) => {
    if (isNaN(c)) return c;
    return numberFormatter.format(c);
  },
});
