import { DeliveryStatus } from '../models/delivery/Delivery';

export const getStatusName = (type: DeliveryStatus): string => {
  switch (type) {
    case DeliveryStatus.arranged:
      return 'Összekészítve';
    case DeliveryStatus.assigned:
      return 'Hozzárendelve';
    case DeliveryStatus.done:
      return 'Kiszállítva';
    case DeliveryStatus.inDelivery:
      return 'Szállítas alatt';
    case DeliveryStatus.new:
      return 'Új';
  }

  return '';
};
