import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { User, UserRole } from '../models/users/User';

const initialState: User = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  active: true,
  roleId: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<User>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.roleId = action.payload.roleId;
      state.id = action.payload.id;
    },
  },
});

export const { setUserProfile } = userSlice.actions;

export const selectUserProfile = (state: RootState) => state.localData.user;
export const isAdminRole = (state: RootState) => state.localData.user.roleId === UserRole.admin;

export default userSlice.reducer;
