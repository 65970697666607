import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { UsersState } from '../models/slices/UsersState';
import { LoadUsersResponse } from '../models/users/LoadUsersResponse';
import { SaveUserResponse } from '../models/users/SaveUserResponse';
import { User } from '../models/users/User';

const initialState: UsersState = {
  users: undefined,
  user: undefined,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadUsersResponse | undefined>) => {
      state.users = action.payload?.users;
    },
    setUsers: (state, action: PayloadAction<User[] | null | undefined>) => {
      state.users = action.payload;
    },
    updateState: (state, action: PayloadAction<SaveUserResponse>) => {
      if (action.payload.user) {
        state.user = action.payload.user;
      }
    },
  },
});

export const { setResponse, updateState, setUsers } = usersSlice.actions;

export const selectUsers = (state: RootState) => state.localData.users.users;

export default usersSlice.reducer;
