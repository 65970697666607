import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slices/userSlice';
import usersReducer from '../slices/usersSlice';
import vehiclesReducer from '../slices/vehiclesSlice';
import ordersReducer from '../slices/ordersSlice';
import applicationReducer from '../slices/applicationSlice';
import deliveriesReducer from '../slices/deliveriesSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
};

const localStoredReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
  users: usersReducer,
  vehicles: vehiclesReducer,
  orders: ordersReducer,
  deliveries: deliveriesReducer,
});

const persistedReducer = persistReducer(persistConfig, localStoredReducer);

const rootReducer = combineReducers({
  localData: persistedReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
