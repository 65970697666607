import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { securityClient } from '../../security/securityClient';
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../../slices/userSlice';

interface IdleDetectorProps {
  idleTime: number;
  promptTime: number;
}

export const IdleDetector: React.FC<IdleDetectorProps> = ({ idleTime, promptTime }: IdleDetectorProps) => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(promptTime);
  const dispatch = useDispatch();

  useEffect(() => {
    let timer: NodeJS.Timeout | null;
    if (open) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            return 100;
          }
          return oldProgress + 100 / promptTime;
        });
        setTimeLeft((oldTime) => oldTime - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [open, promptTime]);

  const onIdle = () => {
    dispatch(setUserProfile({ email: '', firstName: '', lastName: '', roleId: 0, id: 0, phone: '', active: false }));
    securityClient.signOut();
  };

  const onPrompt = () => {
    // Fire a Modal Prompt
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const stayTuned = () => {
    handleClose();
    setProgress(0);
    setTimeLeft(promptTime);
    securityClient.getAccount();
    reset();
  };

  const onAction = () => {
    stayTuned();
  };

  const { reset } = useIdleTimer({
    onPrompt,
    onAction,
    onIdle,
    timeout: 1000 * idleTime,
    promptTimeout: 1000 * promptTime,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 10000,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={stayTuned}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LinearProgress variant="determinate" value={progress} />
        <DialogTitle id="alert-dialog-title">A munkamenet hamarosan lejár</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Az Ön munkamenete {timeLeft} másodperc múlva le fog járni és minden el nem mentett változtatás elvész.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={stayTuned} color="primary" autoFocus>
            Maradok bejelentkezve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
