import { Box, Button, Grid, InputAdornment, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';
import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Order, OrderStatus } from '../../../models/orders/Order';
import { FormTextField } from '../../common/input/FormTextField';
import { FormCheckboxWithLabel } from '../../common/input/FormCheckboxWithLabel';
import { Client } from '../../../models/orders/Client';
import { OrderItem } from '../../../models/orders/OrderItem';
import { Type } from './Type';
import { Status } from './Status';
import { useMemo } from 'react';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import { getTypeName } from '../../../utility/orders';
import { useFormat } from '../../../utility/useFormat';

interface Props {
  data: Order;
  onClose: () => void;
}

export const Details: React.FC<Props> = ({ data, onClose }: Props) => {
  const { formatDate } = useFormat();
  const headCells: TableHeaderCell[] = [
    {
      id: 'product',
      isNumeric: false,
      label: 'Termék',
      disablePadding: true,
      width: '60%',
    },
    {
      id: 'quantity',
      isNumeric: false,
      label: 'Mennyiség',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'weight',
      isNumeric: false,
      label: 'Össztömeg (kg)',
      disablePadding: true,
      width: '20%',
    },
  ];

  const orderItemsData: TableRowData[] = useMemo(() => {
    return (data.items ?? []).map((orderItem: OrderItem) => ({
      rowId: `order-item-${orderItem.id}`,
      id: orderItem.id,
      columns: [
        {
          id: 'product',
          cValue: orderItem.name,
          value: orderItem.name,
        },
        {
          id: 'quantity',
          cValue: orderItem.count,
          value: orderItem.count,
        },
        {
          id: 'weight',
          cValue: orderItem.totalWeight,
          value: orderItem.totalWeight,
        },
      ],
    }));
  }, [data.items]);

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 600 }, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h4">#{data.number} Rendelés</Typography>
            <Typography variant="body1">{getTypeName(data.type)}</Typography>
            <Typography variant="body1">{formatDate(data.orderDate)}</Typography>
          </Box>
          <Type data={data.type} />
        </Box>
        <Box sx={{ pt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant={'h3'}>{data.client.name}</Typography>
            <Typography variant={'body1'}>{data.client.address}</Typography>
            <Typography variant={'body1'}>{data.client.contactPerson}</Typography>
          </Box>
          <Status data={data.status} />
        </Box>
        <Box sx={{ pt: 4 }}>
          <Table
            id="order-items"
            headCells={headCells}
            rows={orderItemsData}
            hideOthersOnSelect={true}
            selected={undefined}
            setSelected={() => {}}
            hidePaper
          />
        </Box>
        <Box sx={{ pt: 4 }}>
          <Typography variant="h5">Kiszállítás</Typography>
          {data.deliveryDate && (
            <Typography variant="body1">{data.deliveryDate ? formatDate(data.deliveryDate) : ''}</Typography>
          )}

          <Grid container sx={{mt: 1}}>
            {data.vehicle && (
              <Grid item xs={data.driver ? 6 : 12}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon sx={{ mr: 1 }} />
                  Jármű
                </Typography>
                <Typography variant="body1">
                  {data.vehicle.brand} {data.vehicle.model}
                </Typography>
                <Typography variant="body1">{data.vehicle.licensePlate}</Typography>
              </Grid>
            )}
            {data.driver && (
              <Grid item xs={data.vehicle ? 6 : 12}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ mr: 1 }} />
                  Soför
                </Typography>
                <Typography variant="body1">{data.driver.name}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
