import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Access } from '../../../access/Access';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Button, Container, IconButton, TextField, Typography } from '@mui/material';
import { Card } from '../../common/Card';
import { addMessage } from '../../../slices/applicationSlice';
import { TableHeaderCell, TableRowData, Table } from '../../common/Table';

// import { EditOrder, OrderValues } from './EditOrder';
import { SaveOrderRequest } from '../../../models/orders/SaveOrderRequest';
import { SaveOrderResponse } from '../../../models/orders/SaveOrderResponse';
import RefreshIcon from '@mui/icons-material/Refresh';
import { isAdminRole } from '../../../slices/userSlice';

import { getTypeName } from '../../../utility/orders';
import ListIcon from '@mui/icons-material/List';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { useFormat } from '../../../utility/useFormat';
import {
  selectActiveDeliveries,
  selectClosedDeliveries,
  setActiveDeliveries,
  setResponse,
} from '../../../slices/deliveriesSlice';
import { Delivery, DeliveryStatus } from '../../../models/delivery/Delivery';
import { LoadDeliveriesResponse } from '../../../models/delivery/LoadDeliveriesResponse';
import { LoadDeliveriesRequest } from '../../../models/delivery/LoadDeliveriesRequest';
import { ActiveDeliveries } from './ActiveDeliveries';
import { DeliveryValues, EditDelivery } from './EditDelivery';
import { SaveDeliveryRequest } from '../../../models/delivery/SaveDeliveryRequest';
import { SaveDeliveryResponse } from '../../../models/delivery/SaveDeliveryResponse';
import { Order } from '../../../models/orders/Order';
import { Details } from '../orders/Details';

export const Deliveries: React.FC = () => {
  const dispatch = useDispatch();
  const { formatDate } = useFormat();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const activeDeliveries: Delivery[] | undefined | null = useSelector(selectActiveDeliveries);
  const closedDeliveries: Delivery[] | undefined | null = useSelector(selectClosedDeliveries);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Order | undefined>(undefined);
  // const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | undefined>(undefined);
  // const [selectedStatuses, setSelectedStatuses] = useState<number[]>([
  //   OrderStatus.new,
  //   OrderStatus.arranged,
  //   OrderStatus.assigned,
  //   OrderStatus.inDelivery,
  //   OrderStatus.done,
  // ]);

  // const [selectedOrderDetails, setSelectedOrderDetails] = useState<Order | undefined>(undefined);

  useEffect(() => {
    if (activeDeliveries === undefined && !isLoading) {
      setIsLoading(true);
      dispatch(setActiveDeliveries(null));

      const request: LoadDeliveriesRequest = { getActiveDeliveries: {} };

      const access = new Access();
      access
        .loadDeliveries(request)
        .then((value: LoadDeliveriesResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeliveries]);

  // const headCells: TableHeaderCell[] = [
  //   {
  //     id: 'number',
  //     isNumeric: false,
  //     label: 'Szám / dátum',
  //     disablePadding: true,
  //     width: '15%',
  //   },
  //   {
  //     id: 'type',
  //     isNumeric: false,
  //     label: 'Cég',
  //     disablePadding: true,
  //     width: '10%',
  //   },
  //   {
  //     id: 'client',
  //     isNumeric: false,
  //     label: 'Vásárló',
  //     disablePadding: true,
  //     width: '20%',
  //   },
  //   {
  //     id: 'address',
  //     isNumeric: false,
  //     label: 'Cím',
  //     disablePadding: true,
  //     width: '15%',
  //   },
  //   {
  //     id: 'delivery',
  //     isNumeric: false,
  //     label: 'Kiszállítás',
  //     disablePadding: true,
  //     width: '15%',
  //   },
  //   {
  //     id: 'status',
  //     isNumeric: false,
  //     label: 'Státus',
  //     disablePadding: true,
  //     width: '15%',
  //   },
  //   {
  //     id: 'operation',
  //     isNumeric: false,
  //     label: '',
  //     disablePadding: true,
  //     width: '10%',
  //   },
  // ];

  // const saveOrder = (values: OrderValues) => {
  //   const access = new Access();
  //   const orderCriteria: Order = {
  //     ...values,
  //   };
  //   const request: SaveOrderRequest = {
  //     saveOrder: orderCriteria,
  //   };

  //   access
  //     .saveOrder(request)
  //     .then((value: SaveOrderResponse | undefined) => {
  //       if (value?.error == null) {
  //         dispatch(setOrders(undefined));
  //         dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-order' }));
  //       } else {
  //         dispatch(addMessage(value?.error ?? 'Hiba történt'));
  //       }
  //     })
  //     .catch((reason: any) => {
  //       dispatch(addMessage(reason.message ?? 'Hiba történt'));
  //     })
  //     .finally(() => {
  //       setSelectedOrder(undefined);
  //     });
  // };

  // const handleChangeSearchTerm = (event: any) => {
  //   const value = event.target.value;
  //   setSearchTerm(value);
  // };

  const handleCreateDelivery = (): void => {
    setSelectedDelivery({
      id: 0,
      date: new Date(),
      driver: undefined,
      loadWeight: 0,
      orders: [],
      status: DeliveryStatus.new,
      vehicle: undefined,
    });
  };

  const saveDelivery = (values: DeliveryValues): void => {
    if (values.vehicleId && values.driverId) {
      const access = new Access();
      const date = formatDate(values.date, 'yyyy-MM-DD');

      const deliveryCriteria: Delivery = {
        id: values.id,
        date: date as any,
        loadWeight: 0,
        orders: [],
        status: values.status,
        driver: {
          id: parseInt(values.driverId),
          name: '',
        },
        vehicle: {
          id: parseInt(values.vehicleId),
          active: true,
          brand: '',
          licensePlate: '',
          loadWeight: 0,
          model: '',
        },
      };
      const request: SaveDeliveryRequest = {
        saveDelivery: deliveryCriteria,
      };

      access
        .saveDelivery(request)
        .then((value: SaveDeliveryResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setActiveDeliveries(undefined));
            setSelectedDelivery(undefined);
            dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-vehicle' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setActiveDeliveries(undefined);
        });
    }
  };
  const handleEditDelivery = (delivery: Delivery): void => setSelectedDelivery(delivery);

  const refreshList = () => dispatch(setActiveDeliveries(undefined));

  // const ordersData: TableRowData[] = useMemo(
  //   () => {
  //     const term = searchTerm ? searchTerm.toLowerCase() : undefined;
  //     return (orders ?? [])
  //       .filter(
  //         (order: Order) =>
  //           (selectedStatuses.length === 0 || selectedStatuses.some((s: number) => s === order.status)) &&
  //           (!term ||
  //             order.client?.name == null ||
  //             order.client.name.toLowerCase().indexOf(term) > -1 ||
  //             order.client?.address == null ||
  //             order.client.address.toLowerCase().indexOf(term) > -1 ||
  //             order.client?.contactPerson == null ||
  //             order.client.contactPerson.toLowerCase().indexOf(term) > -1 ||
  //             order.vehicle?.licensePlate == null ||
  //             order.vehicle.licensePlate.toLowerCase().indexOf(term) > -1 ||
  //             order.vehicle?.brand == null ||
  //             order.vehicle.brand.toLowerCase().indexOf(term) > -1 ||
  //             order.vehicle?.model == null ||
  //             order.vehicle.model.toLowerCase().indexOf(term) > -1 ||
  //             order.driver?.name == null ||
  //             order.driver.name.toLowerCase().indexOf(term) > -1)
  //       )
  //       .map((order: Order) => ({
  //         rowId: `order-${order.id}`,
  //         id: order.id,
  //         columns: [
  //           {
  //             id: 'number',
  //             cValue: order.number,
  //             value: (
  //               <>
  //                 {order.number} / {formatDate(order.orderDate)}
  //               </>
  //             ),
  //           },
  //           {
  //             id: 'type',
  //             cValue: order.type,
  //             value: getTypeName(order.type),
  //           },
  //           {
  //             id: 'client',
  //             cValue: order.client.name,
  //             value: order.client.name,
  //           },
  //           {
  //             id: 'address',
  //             cValue: order.client.address,
  //             value: <>{order.client.address}</>,
  //           },
  //           {
  //             id: 'delivery',
  //             cValue: order.deliveryDate,
  //             value: (
  //               <>
  //                 {order.deliveryDate ? formatDate(order.deliveryDate) : '-'}
  //                 {order.vehicle && (
  //                   <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
  //                     <LocalShippingIcon sx={{ mr: 1 }} /> {order.vehicle.brand} {order.vehicle.model}
  //                   </Typography>
  //                 )}
  //                 {order.driver && (
  //                   <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
  //                     <PersonIcon sx={{ mr: 1 }} /> {order.driver.name}
  //                   </Typography>
  //                 )}
  //               </>
  //             ),
  //           },
  //           {
  //             id: 'status',
  //             cValue: order.status,
  //             value: <Status data={order.status} />,
  //           },
  //           {
  //             id: 'operation',
  //             cValue: '',
  //             value: (
  //               <>
  //                 <Button
  //                   startIcon={<ListIcon />}
  //                   color="primary"
  //                   size="small"
  //                   onClick={() => setSelectedOrderDetails(order)}
  //                 >
  //                   Infó
  //                 </Button>
  //                 {/* <Button startIcon={<EditIcon />} color="primary" size="small" onClick={() => setSelectedOrder(order)}>
  //                   Szerkeszt
  //                 </Button> */}
  //               </>
  //             ),
  //           },
  //         ],
  //       }));
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [orders, selectedStatuses, searchTerm]
  // );

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {activeDeliveries === undefined ? (
              <Alert severity="warning">Nincenek kiszállítások</Alert>
            ) : (
              <>
                <Box
                  sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                  <Button
                    variant="contained"
                    sx={{ my: 2 }}
                    startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                    color="primary"
                    onClick={handleCreateDelivery}
                  >
                    Új kiszállítás
                  </Button>
                  <IconButton sx={{ my: 2 }} color="primary" onClick={refreshList} title="Frissítés">
                    <RefreshIcon />
                  </IconButton>
                </Box>
                {activeDeliveries != null && (
                  <ActiveDeliveries
                    data={activeDeliveries!}
                    onEdit={handleEditDelivery}
                    onSelectOrder={setSelectedOrderDetails}
                  />
                )}
                {/* <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-end', mb: 2 }}>
                  <Box sx={{ flexGrow: 4 }}>
                    <StatusFilter selected={selectedStatuses} setSelected={setSelectedStatuses} />
                  </Box>
                  <TextField
                    id="search-term-orders"
                    label="Szűrés"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    size="small"
                    sx={{ flexGrow: 1, ml: 2 }}
                  />
                </Box>
                <Table
                  id="orders"
                  headCells={headCells}
                  rows={ordersData}
                  hideOthersOnSelect={true}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 25 }}
                />
               
                {selectedOrderDetails && (
                  <Details data={selectedOrderDetails} onClose={() => setSelectedOrderDetails(undefined)} />
                )} */}

                {selectedDelivery && (
                  <EditDelivery
                    data={selectedDelivery}
                    onClose={() => setSelectedDelivery(undefined)}
                    onSave={saveDelivery}
                  />
                )}

                {selectedOrderDetails && (
                  <Details data={selectedOrderDetails} onClose={() => setSelectedOrderDetails(undefined)} />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
