import { ValidateUser } from '../models/ValidateUser';

import { AppAccount } from '../models/Account';
import { IAuthClient } from './IAuthClient';
import { Access } from '../access/Access';
import { SignInRequest } from '../models/SignInRequest';
import { SignInResponse } from '../models/SignInResponse';

export class AuthClient implements IAuthClient {
  tokenName: string = 'Authorization';

  public isInitialized: boolean = false;

  initialize = (): Promise<AppAccount | undefined | 'unauthorized'> => {
    return new Promise((resolve, reject) => {
      const token = this.getToken();

      if (token == null) {
        this.isInitialized = true;

        resolve(undefined);
      }

      this.getAccount()
        .then((user: AppAccount | undefined | 'unauthorized') => {
          this.isInitialized = true;

          resolve(user);
        })
        .catch(() => reject());
    });
  };

  redirectSignIn = (): void => {
    window.location.href = `${window.location.origin}/sign-in`;
  };

  redirectForgotPassword = (): void => {};

  getAccount = (): Promise<AppAccount | undefined> => {
    return new Promise((resolve, reject) => {
      const access = new Access();

      access
        .validateUser()
        .then((result: ValidateUser | undefined) => {
          this.setToken(result?.token);
          resolve(result?.user);
        })
        .catch(() => reject());
    });
  };

  hasAnyRole = async (roles: number[] | undefined): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (!roles || roles.length === 0) return true;

      this.getAccount()
        .then((account: AppAccount | undefined) => {
          const response = account ? roles.some((value: number) => value === account.roleId) : false;
          resolve(response);
        })
        .catch(() => reject());
    });
  };

  signIn = async (criteria: SignInRequest): Promise<SignInResponse | undefined> => {
    const promise: Promise<SignInResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const access = new Access();
          access
            .signIn(criteria)
            .then((value: SignInResponse | undefined) => {
              if (value?.error == null && value?.token) {
                this.setToken(value.token);
                resolutionFunc(value);
              } else {
                rejectFunction({ message: value?.error ?? 'Belépés megtagadva' });
              }
            })
            .catch((reason) => {
              rejectFunction(reason);
            });
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  };

  signOut = () => {
    localStorage.removeItem(this.tokenName);
    this.redirectSignIn();
  };

  setToken(token: string | undefined): void {
    if (token) localStorage.setItem(this.tokenName, token);
    else localStorage.removeItem(this.tokenName);
  }

  getToken(): string | undefined {
    return localStorage.getItem(this.tokenName) ?? undefined;
  }
}
