import { ReactElement } from 'react';
import { Chip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { OrderStatus } from '../../../models/orders/Order';
import { getStatusName } from '../../../utility/orders';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import InventoryIcon from '@mui/icons-material/Inventory';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

interface Props {
  data: OrderStatus;
}

export const getStatus = (status: OrderStatus): { label: string; color: any; icon: ReactElement } | undefined => {
  if (status == null) return undefined;
  switch (status) {
    case OrderStatus.new:
      return {
        label: getStatusName(status),
        color: 'primary',
        icon: <NewReleasesIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    case OrderStatus.arranged:
      return {
        label: getStatusName(status),
        color: 'secondary',
        icon: <InventoryIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    case OrderStatus.assigned:
      return {
        label: getStatusName(status),
        color: 'info',
        icon: <CompareArrowsIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    case OrderStatus.inDelivery:
      return {
        label: getStatusName(status),
        color: 'warning',
        icon: <LocalShippingIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    case OrderStatus.done:
      return {
        label: getStatusName(status),
        color: 'success',
        icon: <DoneIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />,
      };
    default:
      return { label: 'Inaktív', color: 'default', icon: <CloseIcon /> };
  }
};

export const Status: React.FC<Props> = ({ data }: Props) => {
  const status = getStatus(data);
  return status ? (
    <Chip
      size="small"
      avatar={status.icon}
      sx={{ my: 0.3, minWidth: 125 }}
      color={status.color}
      label={status.label}
      onDelete={undefined}
    />
  ) : null;
};
