import { Box } from '@mui/material';
import { Delivery as DeliveryModel } from '../../../models/delivery/Delivery';
import { Delivery } from './Delivery';
import { Order } from '../../../models/orders/Order';

interface Props {
  data: DeliveryModel[];
  onEdit: (delivery: DeliveryModel) => void;
  onSelectOrder: (order: Order | undefined) => void;
}

export const ActiveDeliveries: React.FC<Props> = ({ data, onEdit, onSelectOrder }: Props) => {
  return (
    <>
      {data.map((delivery: DeliveryModel) => (
        <Box key={delivery.id} sx={{ mb: 3 }}>
          <Delivery data={delivery} onEdit={onEdit} onSelectOrder={onSelectOrder} />
        </Box>
      ))}
    </>
  );
};
