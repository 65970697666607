export class User {
  id!: number;
  firstName!: string;
  lastName!: string;
  phone!: string;
  email!: string;
  roleId!: number;
  active!: boolean;
  pw?: string;
}

export enum UserRole {
  admin = 1,
  driver = 2,
  dispatcher = 3,
  packer = 4,
}
