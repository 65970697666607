import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { OrdersState } from '../models/slices/OrdersState';
import { LoadOrdersResponse } from '../models/orders/LoadOrdersResponse';
import { SaveOrderResponse } from '../models/orders/SaveOrderResponse';
import { Order } from '../models/orders/Order';

const initialState: OrdersState = {
  orders: undefined,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadOrdersResponse | undefined>) => {
      state.orders = action.payload?.orders;
    },
    setOrders: (state, action: PayloadAction<Order[] | null | undefined>) => {
      state.orders = action.payload;
    },
    updateState: (state, action: PayloadAction<SaveOrderResponse>) => {
      if (action.payload.order) {
       // state.order = action.payload.order;
      }
    },
  },
});

export const { setResponse, updateState, setOrders } = ordersSlice.actions;

export const selectOrders = (state: RootState) => state.localData.orders.orders;

export default ordersSlice.reducer;
