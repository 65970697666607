import { UserRole } from '../models/users/User';

export const getRoleName = (roleId: number): string => {
  switch (roleId) {
    case UserRole.admin:
      return 'Admin';
    case UserRole.dispatcher:
      return 'Diszpécser';
    case UserRole.driver:
      return 'Sofőr';
    case UserRole.packer:
      return 'Összekészítő';
  }

  return '';
};

export interface UserValues {
  id: number;
  firstName: string;
  lastName: string;
  active: boolean;
  phone: string;
  email: string;
  roleId: number;
  pw: string;
}
