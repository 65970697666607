import {
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { User, UserRole } from '../../../models/users/User';
import { FormTextField } from '../../common/input/FormTextField';
import { getRoleName, UserValues } from '../../../utility/users';
import { FormSelect } from '../../common/input/FormSelect';
import { useMemo } from 'react';
import { FormCheckboxWithLabel } from '../../common/input/FormCheckboxWithLabel';

interface Props {
  data: User;
  onClose: () => void;
  onSave: (values: UserValues) => void;
}

export const EditUser: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const initialValues: UserValues = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    active: data.active,
    email: data.email,
    phone: data.phone,
    roleId: data.roleId,
    pw: data.pw ?? '',
  };

  const handleSubmit = (value: UserValues): void => onSave(value);
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Kérem adja meg a keresztnevet'),
    lastName: Yup.string().required('Kérem adja meg a vezetéknevet'),
    email: Yup.string().email().required('Kérem adja meg az email címet'),
    pw: Yup.string().when('id', (values, schema) => {
      const [id] = values;
      if (id === 0) return schema.required('Kérem adja meg a jelszót');
      return schema;
    }),
  });

  const roleOptions = useMemo(
    () =>
      Object.keys(UserRole)
        .filter((key: any) => !isNaN(Number(UserRole[key])))
        .map((key) => {
          let value = (UserRole as any)[key];
          let label = getRoleName(value);
          return {
            key: value,
            value: label,
          };
        }),
    []
  );

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{data.id > 0 ? 'Szerkesztés' : 'Létrehozás'}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <>
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  <Box pb={2}>
                    <FormTextField size="small" label="Vezetéknév" name="lastName" id="lastName" fullWidth />
                  </Box>

                  <Box pb={2}>
                    <FormTextField size="small" label="Keresztnév" name="firstName" id="firstName" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField size="small" label="Email" name="email" id="email" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField size="small" label="Telefonszám" name="phone" id="phone" fullWidth />
                  </Box>

                  <Box pb={2}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <InputLabel id="roleId-label" sx={{ ml: -2 }}>
                        Típus
                      </InputLabel>
                      <FormSelect labelId="roleId-label" name="roleId" id="roleId" variant="standard">
                        {(roleOptions ?? []).map((option) => (
                          <MenuItem value={option.key} key={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormCheckboxWithLabel label="Aktív" name="active" />
                  </Box>

                  <Box pb={2}>
                    <FormTextField
                      size="small"
                      label="Jelszó"
                      name="pw"
                      id="pw"
                      type="password"
                      fullWidth
                      InputProps={{ autoComplete: 'new-password' }}
                    />
                    {data.id !== 0 && (
                      <Typography variant="caption">Hagyja üressen, ha nem szeretné megváltoztatni!</Typography>
                    )}
                  </Box>

                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
