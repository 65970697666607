import { Container, Card, Typography, Grid } from '@mui/material';
import { Password } from './Password';
import { selectUserProfile } from '../../../slices/userSlice';
import { useSelector } from 'react-redux';
import { MyProfile } from './MyProfile';

export const MyAccount: React.FC = () => {
  const profile = useSelector(selectUserProfile);
  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh', p: 2 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {profile.lastName} {profile.firstName}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MyProfile data={profile} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Password />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
