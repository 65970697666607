import { Box, Button, LinearProgress, Typography, Card } from '@mui/material';
import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { User } from '../../../models/users/User';

import { FormTextField } from '../../common/input/FormTextField';
import { Access } from '../../../access/Access';
import { SaveUserRequest } from '../../../models/users/SaveUserRequest';
import { SaveUserResponse } from '../../../models/users/SaveUserResponse';
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../../../slices/userSlice';
import { addMessage } from '../../../slices/applicationSlice';
import { UserValues } from '../../../utility/users';
import { useRef } from 'react';

interface Props {
  data: User;
}

export const MyProfile: React.FC<Props> = ({ data }: Props) => {
  const dispatch = useDispatch();

  let ref = useRef(null) as any;

  const initialValues: UserValues = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    active: true,
    email: data.email,
    phone: data.phone,
    roleId: data.roleId,
    pw: '',
  };

  const handleSubmit = (value: UserValues): void => {
    const access = new Access();
    const userCriteria: User = {
      ...value,
    };
    const request: SaveUserRequest = {
      saveUser: userCriteria,
    };

    access
      .saveUser(request)
      .then((value: SaveUserResponse | undefined) => {
        if (value?.error == null) {
          if (value?.user != null) {
            dispatch(setUserProfile(value.user));
          }
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-user' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        if (ref) ref.setSubmitting(false);
      });
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Kérem adja meg a keresztnevet'),
    lastName: Yup.string().required('Kérem adja meg a vezetéknevet'),
    email: Yup.string().email().required('Kérem adja meg az email címet'),
  });

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">Saját profilom</Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={(instance) => {
          ref = instance;
        }}
      >
        {({ isSubmitting }) => (
          <>
            {isSubmitting && (
              <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                <LinearProgress />
              </Box>
            )}
            <Form>
              <Box my={2}>
                <FormTextField size="small" label="Vezetéknév" name="lastName" id="lastName" fullWidth />
              </Box>

              <Box my={2}>
                <FormTextField size="small" label="Keresztnév" name="firstName" id="firstName" fullWidth />
              </Box>
              <Box my={2}>
                <FormTextField size="small" label="Email" name="email" id="email" fullWidth />
              </Box>
              <Box my={2}>
                <FormTextField size="small" label="Telefonszám" name="phone" id="phone" fullWidth />
              </Box>

              <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Mentés
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};
