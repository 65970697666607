import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Access } from '../../../access/Access';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Button, Container, IconButton, TextField, Typography } from '@mui/material';
import { Card } from '../../common/Card';
import { addMessage } from '../../../slices/applicationSlice';
import { Order, OrderStatus } from '../../../models/orders/Order';
import { selectOrders, setResponse, setOrders } from '../../../slices/ordersSlice';
import { LoadOrdersResponse } from '../../../models/orders/LoadOrdersResponse';
import { TableHeaderCell, TableRowData, Table } from '../../common/Table';
import { EditOrder, OrderValues } from './EditOrder';
import { SaveOrderRequest } from '../../../models/orders/SaveOrderRequest';
import { SaveOrderResponse } from '../../../models/orders/SaveOrderResponse';
import RefreshIcon from '@mui/icons-material/Refresh';
import { isAdminRole } from '../../../slices/userSlice';
import { Status } from './Status';
import { getTypeName } from '../../../utility/orders';
import ListIcon from '@mui/icons-material/List';
import { Details } from './Details';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import { useFormat } from '../../../utility/useFormat';
import { StatusFilter } from './StatusFilter';

export const Orders: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const orders: Order[] | undefined | null = useSelector(selectOrders);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([
    OrderStatus.new,
    OrderStatus.arranged,
    OrderStatus.assigned,
    OrderStatus.inDelivery,
    OrderStatus.done,
  ]);

  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Order | undefined>(undefined);
  const { formatDate } = useFormat();
  useEffect(() => {
    if (orders === undefined && !isLoading) {
      setIsLoading(true);
      dispatch(setOrders(null));
      const access = new Access();
      access
        .loadOrders({ status: [] })
        .then((value: LoadOrdersResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'number',
      isNumeric: false,
      label: 'Szám / dátum',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'type',
      isNumeric: false,
      label: 'Cég',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'client',
      isNumeric: false,
      label: 'Vásárló',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'delivery',
      isNumeric: false,
      label: 'Kiszállítás',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'status',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const saveOrder = (values: OrderValues) => {
    const access = new Access();
    const orderCriteria: Order = {
      ...values,
    };
    const request: SaveOrderRequest = {
      saveOrder: orderCriteria,
    };

    access
      .saveOrder(request)
      .then((value: SaveOrderResponse | undefined) => {
        if (value?.error == null) {
          dispatch(setOrders(undefined));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-order' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setSelectedOrder(undefined);
      });
  };

  const handleChangeSearchTerm = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const refreshList = () => dispatch(setOrders(undefined));

  const ordersData: TableRowData[] = useMemo(
    () => {
      const term = searchTerm ? searchTerm.toLowerCase() : undefined;
      return (orders ?? [])
        .filter(
          (order: Order) =>
            (selectedStatuses.length === 0 || selectedStatuses.some((s: number) => s === order.status)) &&
            (!term ||
              order.client?.name == null ||
              order.client.name.toLowerCase().indexOf(term) > -1 ||
              order.client?.address == null ||
              order.client.address.toLowerCase().indexOf(term) > -1 ||
              order.client?.contactPerson == null ||
              order.client.contactPerson.toLowerCase().indexOf(term) > -1 ||
              order.vehicle?.licensePlate == null ||
              order.vehicle.licensePlate.toLowerCase().indexOf(term) > -1 ||
              order.vehicle?.brand == null ||
              order.vehicle.brand.toLowerCase().indexOf(term) > -1 ||
              order.vehicle?.model == null ||
              order.vehicle.model.toLowerCase().indexOf(term) > -1 ||
              order.driver?.name == null ||
              order.driver.name.toLowerCase().indexOf(term) > -1)
        )
        .map((order: Order) => ({
          rowId: `order-${order.id}`,
          id: order.id,
          columns: [
            {
              id: 'number',
              cValue: order.number,
              value: (
                <>
                  {order.number} / {formatDate(order.orderDate)}
                </>
              ),
            },
            {
              id: 'type',
              cValue: order.type,
              value: getTypeName(order.type),
            },
            {
              id: 'client',
              cValue: order.client.name,
              value: order.client.name,
            },
            {
              id: 'address',
              cValue: order.client.address,
              value: <>{order.client.address}</>,
            },
            {
              id: 'delivery',
              cValue: order.deliveryDate,
              value: (
                <>
                  {order.deliveryDate ? formatDate(order.deliveryDate) : '-'}
                  {order.vehicle && (
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocalShippingIcon sx={{ mr: 1 }} /> {order.vehicle.brand} {order.vehicle.model}
                    </Typography>
                  )}
                  {order.driver && (
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                      <PersonIcon sx={{ mr: 1 }} /> {order.driver.name}
                    </Typography>
                  )}
                </>
              ),
            },
            {
              id: 'status',
              cValue: order.status,
              value: <Status data={order.status} />,
            },
            {
              id: 'operation',
              cValue: '',
              value: (
                <>
                  <Button
                    startIcon={<ListIcon />}
                    color="primary"
                    size="small"
                    onClick={() => setSelectedOrderDetails(order)}
                  >
                    Infó
                  </Button>
                  {/* <Button startIcon={<EditIcon />} color="primary" size="small" onClick={() => setSelectedOrder(order)}>
                    Szerkeszt
                  </Button> */}
                </>
              ),
            },
          ],
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orders, selectedStatuses, searchTerm]
  );

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {orders === undefined ? (
              <Alert severity="warning">Nincenek rendelések</Alert>
            ) : (
              <>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <IconButton sx={{ my: 2 }} color="primary" onClick={refreshList} title="Frissítés">
                    <RefreshIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-end', mb: 2 }}>
                  <Box sx={{ flexGrow: 4 }}>
                    <StatusFilter selected={selectedStatuses} setSelected={setSelectedStatuses} />
                  </Box>
                  <TextField
                    id="search-term-orders"
                    label="Szűrés"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    size="small"
                    sx={{ flexGrow: 1, ml: 2 }}
                  />
                </Box>
                <Table
                  id="orders"
                  headCells={headCells}
                  rows={ordersData}
                  hideOthersOnSelect={true}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 25 }}
                />
                {selectedOrder && (
                  <EditOrder data={selectedOrder} onClose={() => setSelectedOrder(undefined)} onSave={saveOrder} />
                )}
                {selectedOrderDetails && (
                  <Details data={selectedOrderDetails} onClose={() => setSelectedOrderDetails(undefined)} />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
