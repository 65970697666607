import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chip, Input } from '@mui/material';
import React from 'react';
import { UserRole } from '../../../models/users/User';
import { getRoleName } from '../../../utility/users';

interface Props {
  selected: number[];
  setSelected: (values: number[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RoleFilter: React.FC<Props> = ({ selected, setSelected }: Props) => {
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',').map((x: string) => parseInt(value)) : value
    );
  };

  return (
    <div>
      <FormControl sx={{ my: 3 }} fullWidth>
        <InputLabel id="status-label">Típus szűrő</InputLabel>
        <Select
          labelId="status-label"
          id="status-filter"
          multiple
          variant="standard"
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-status" />}
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value: number) => (
                <React.Fragment key={value}>
                  <Chip
                    size="small"
                    sx={{ my: 0.3, minWidth: 105 }}
                    color="default"
                    label={getRoleName(value)}
                    onDelete={undefined}
                  />
                </React.Fragment>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {Object.keys(UserRole)
            .filter((key: any) => !isNaN(Number(UserRole[key])))
            .map((key) => {
              const value = (UserRole as any)[key];
              const label = getRoleName(value);
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};
