import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../components/views/NotFound';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { SignIn } from '../components/views/signIn/SignIn';
import { MyAccount } from '../components/views/myAccount/MyAccount';
import { ForgotPassword } from '../components/views/forgotPassword/ForgotPassword';
import { Dashboard } from '../components/views/dashboard/Dashboard';
import { Users } from '../components/views/users/Users';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { UserRole } from '../models/users/User';
import { Vehicles } from '../components/views/vehicles/Vehicles';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Orders } from '../components/views/orders/Orders';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Deliveries } from '../components/views/deliveries/Deliveries';

export interface AppRoute {
  path: string;
  menu: string;
  name: string;
  element: React.ReactNode;
  icon: React.ReactNode;
  isPublic: boolean;
  showInMenu: boolean;
  exact: boolean;
  hideMenu: boolean;
  roles?: UserRole[];
}

export const routes: AppRoute[] = [
  {
    path: '/',
    name: 'Irányítópult',
    menu: 'dashboard',
    element: <Dashboard />,
    icon: <HomeOutlinedIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/orders',
    name: 'Rendelések',
    menu: 'orders',
    element: <Orders />,
    icon: <ReceiptLongIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
    roles: [UserRole.admin, UserRole.dispatcher, UserRole.driver, UserRole.packer],
  },
  {
    path: '/delivery',
    name: 'Kiszállítás',
    menu: 'delivery',
    element: <Deliveries />,
    icon: <TimelineIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
    roles: [UserRole.admin],
  },
  {
    path: '/vehicles',
    name: 'Járművek',
    menu: 'vehicles',
    element: <Vehicles />,
    icon: <LocalShippingIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
    roles: [UserRole.admin],
  },
  {
    path: '/users',
    name: 'Felhasználók',
    menu: 'users',
    element: <Users />,
    icon: <PeopleAltIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
    roles: [UserRole.admin],
  },
  {
    path: '/my-account',
    name: 'Profilom',
    menu: 'myAccount',
    element: <MyAccount />,
    icon: null,
    isPublic: false,
    showInMenu: false,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/forgot-password',
    name: 'Elfelejtett jelszó',
    menu: 'forgotPassword',
    element: <ForgotPassword />,
    icon: null,
    isPublic: true,
    showInMenu: false,
    exact: true,
    hideMenu: true,
  },
  {
    icon: null,
    path: '/sign-in',
    name: 'Bejelentkezés',
    element: <SignIn />,
    exact: true,
    showInMenu: false,
    isPublic: true,
    menu: 'signin',
    hideMenu: true,
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route: AppRoute) => (
        <Route path={route.path} key={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
