import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Access } from '../../../access/Access';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Button, Checkbox, Container, FormControlLabel, IconButton, TextField } from '@mui/material';
import { Card } from '../../common/Card';
import { addMessage } from '../../../slices/applicationSlice';
import { isAdminRole } from '../../../slices/userSlice';
import { User, UserRole } from '../../../models/users/User';
import { selectUsers, setResponse, setUsers } from '../../../slices/usersSlice';
import { LoadUsersResponse } from '../../../models/users/LoadUsersResponse';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { Status } from './Status';
import EditIcon from '@mui/icons-material/Edit';
import { EditUser } from './EditUser';
import { getRoleName, UserValues } from '../../../utility/users';
import AddIcon from '@mui/icons-material/Add';
import { RoleFilter } from './RoleFilter';
import { SaveUserRequest } from '../../../models/users/SaveUserRequest';
import { SaveUserResponse } from '../../../models/users/SaveUserResponse';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Users: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const users: User[] | undefined | null = useSelector(selectUsers);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([
    UserRole.admin,
    UserRole.driver,
    UserRole.dispatcher,
    UserRole.packer,
  ]);
  const [showOnlyActive, setShowOnlyActive] = useState<boolean>(true);

  useEffect(() => {
    if (users === undefined && !isLoading) {
      setIsLoading(true);
      dispatch(setUsers(null));
      const access = new Access();
      access
        .loadUsers({ getAllUsers: {} })
        .then((value: LoadUsersResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'lastName',
      isNumeric: false,
      label: 'Vezetéknév',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'firstName',
      isNumeric: false,
      label: 'Keresztnév',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'status',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'email',
      isNumeric: false,
      label: 'E-mail',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'phone',
      isNumeric: false,
      label: 'Telefonszám',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'role',
      isNumeric: false,
      label: 'Típus',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const saveUser = (values: UserValues) => {
    const access = new Access();
    const userCriteria: User = {
      ...values,
    };
    const request: SaveUserRequest = {
      saveUser: userCriteria,
    };

    access
      .saveUser(request)
      .then((value: SaveUserResponse | undefined) => {
        if (value?.error == null) {
          dispatch(setUsers(undefined));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-user' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setSelectedUser(undefined);
      });
  };

  const handleCreateUser = (): void => {
    setSelectedUser({
      id: 0,
      firstName: '',
      lastName: '',
      active: true,
      email: '',
      phone: '',
      roleId: 3,
    });
  };

  const handleChangeSearchTerm = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const refreshList = () => dispatch(setUsers(undefined));

  const usersData: TableRowData[] = useMemo(
    () => {
      const term = searchTerm ? searchTerm.toLowerCase() : undefined;
      return (users ?? [])
        .filter(
          (user: User) =>
            (!showOnlyActive || user.active) &&
            (selectedRoles.length === 0 || selectedRoles.some((s: number) => s === user.roleId)) &&
            (!term ||
              user.firstName == null ||
              user.firstName.toLowerCase().indexOf(term) > -1 ||
              user.lastName == null ||
              user.lastName.toLowerCase().indexOf(term) > -1 ||
              user.phone == null ||
              user.phone.toLowerCase().indexOf(term) > -1 ||
              user.email == null ||
              user.email.toLowerCase().indexOf(term) > -1)
        )
        .map((user: User) => ({
          rowId: `task-${user.id}`,
          id: user.id,
          columns: [
            {
              id: 'lastName',
              cValue: user.lastName,
              value: user.lastName,
            },
            {
              id: 'firstName',
              cValue: user.firstName,
              value: user.firstName,
            },
            {
              id: 'status',
              cValue: user.active,
              value: <Status data={user.active} />,
            },
            {
              id: 'email',
              cValue: user.email,
              value: user.email,
            },
            {
              id: 'phone',
              cValue: user.phone,
              value: user.phone,
            },
            {
              id: 'role',
              cValue: user.roleId,
              value: getRoleName(user.roleId),
            },
            {
              id: 'operation',
              cValue: '',
              value: (
                <Button startIcon={<EditIcon />} color="primary" size="small" onClick={() => setSelectedUser(user)}>
                  Szerkeszt
                </Button>
              ),
            },
          ],
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, selectedRoles, showOnlyActive, searchTerm]
  );

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {users === undefined ? (
              <Alert severity="warning">Nincenek felhasználók</Alert>
            ) : (
              <>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{ my: 2 }}
                    startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                    color="primary"
                    onClick={handleCreateUser}
                  >
                    Új felhasználó
                  </Button>
                  <IconButton sx={{ my: 2 }} color="primary" onClick={refreshList} title="Frissítés">
                    <RefreshIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={<Checkbox checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} />}
                    label="Csak aktívak mutatása"
                  />
                  <Box sx={{ flexGrow: 4 }}>
                    <RoleFilter selected={selectedRoles} setSelected={setSelectedRoles} />
                  </Box>
                  <TextField
                    id="search-term-tasks"
                    label="Szűrés"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    size="small"
                    sx={{ flexGrow: 1, ml: 2, mt: 1.7 }}
                  />
                </Box>
                <Table
                  id="users"
                  headCells={headCells}
                  rows={usersData}
                  hideOthersOnSelect={true}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 25 }}
                />
                {selectedUser && (
                  <EditUser data={selectedUser} onClose={() => setSelectedUser(undefined)} onSave={saveUser} />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
