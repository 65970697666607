import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Delivery, DeliveryStatus } from '../../../models/delivery/Delivery';
import { FormDatePicker } from '../../common/input/FormDatePicker';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Access } from '../../../access/Access';
import { LoadVehiclesResponse } from '../../../models/vehicles/LoadVehiclesResponse';
import { Vehicle } from '../../../models/vehicles/Vehicle';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import { FormSelect } from '../../common/input/FormSelect';
import { LoadUsersResponse } from '../../../models/users/LoadUsersResponse';
import { User } from '../../../models/users/User';
import { Driver } from '../../../models/orders/Driver';

export interface DeliveryValues {
  id: number;
  date: any;
  vehicleId: string | undefined;
  driverId: string | undefined;
  status: DeliveryStatus;
}

interface Props {
  data: Delivery;
  onClose: () => void;
  onSave: (values: DeliveryValues) => void;
}

export const EditDelivery: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const initialValues = {
    id: data.id,
    date: moment(data.date),
    vehicleId: data.vehicle?.id?.toString(),
    driverId: data.driver?.id?.toString(),
    status: data.status,
  };
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<Vehicle[] | undefined | null>(undefined);
  const [drivers, setDrivers] = useState<Driver[] | undefined | null>(undefined);

  const handleSubmit = (value: DeliveryValues): void => onSave(value);
  const validationSchema = Yup.object({
    date: Yup.date().required('Kérem adja meg a dátumot'),
    vehicleId: Yup.string().required('Kérem adja meg a járművet'),
    driverId: Yup.string().required('Kérem adja meg a sofőrt'),
  });

  const loadVehicles = (date: Date): void => {
    const access = new Access();
    access
      .loadVehicles({ getAvailableVehicles: { date, deliveryId: data.id } })
      .then((value: LoadVehiclesResponse | undefined) => {
        if (value?.error == null) {
          setVehicles(value?.vehicles);
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      });
  };

  const loadDrivers = (date: Date): void => {
    const access = new Access();
    access
      .loadUsers({ getAvailableDrivers: { date, deliveryId: data.id } })
      .then((value: LoadUsersResponse | undefined) => {
        if (value != null && value.error == null) {
          const users = value.users?.map((user: User) => ({
            id: user.id,
            name: `${user.lastName} ${user.firstName}`,
          }));
          setDrivers(users);
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      });
  };

  useEffect(() => {
    loadVehicles(data.date);
    loadDrivers(data.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.date]);

  const handleDateChange = (value: Date | null): void => {
    if (value) {
      loadVehicles(value);
      loadDrivers(value);
    }
  };

  const vehicleOptions = useMemo(
    () =>
      vehicles
        ? vehicles.map((vehicle: Vehicle) => {
            return {
              key: vehicle.id,
              value: `${vehicle.brand} ${vehicle.model} (${vehicle.licensePlate})`,
            };
          })
        : [],
    [vehicles]
  );

  const driverOptions = useMemo(
    () =>
      drivers
        ? drivers.map((driver: Driver) => {
            return {
              key: driver.id,
              value: driver.name,
            };
          })
        : [],
    [drivers]
  );

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{data.id > 0 ? 'Szerkesztés' : 'Létrehozás'}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values }) => (
              <>
                {JSON.stringify(values)}
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  <Box pb={2}>
                    <FormDatePicker
                      label="Dátum"
                      name="date"
                      closeOnSelect
                      views={['year', 'month', 'day']}
                      slotProps={{
                        textField: { variant: 'standard', fullWidth: true, id: 'date', size: 'small' },
                      }}
                      onChange={handleDateChange}
                    />
                  </Box>

                  <Box pb={2}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <InputLabel id="roleId-label" sx={{ ml: -2 }}>
                        Jármű
                      </InputLabel>
                      {vehicles == null ? (
                        <LinearProgress />
                      ) : (
                        <FormSelect labelId="roleId-label" name="vehicleId" id="vehicleId" variant="standard">
                          {(vehicleOptions ?? []).map((option) => (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    </FormControl>
                  </Box>

                  <Box pb={2}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <InputLabel id="roleId-label" sx={{ ml: -2 }}>
                        Sofőr
                      </InputLabel>
                      {drivers == null ? (
                        <LinearProgress />
                      ) : (
                        <FormSelect labelId="roleId-label" name="driverId" id="driverId" variant="standard">
                          {(driverOptions ?? []).map((option) => (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    </FormControl>
                  </Box>

                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
