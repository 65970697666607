import { Driver } from '../orders/Driver';
import { Order } from '../orders/Order';
import { Vehicle } from '../vehicles/Vehicle';

export class Delivery {
  id!: number;
  date!: Date;
  driver?: Driver;
  vehicle?: Vehicle;
  orders!: Order[];
  loadWeight!: number;
  status!: DeliveryStatus;
}

export enum DeliveryStatus {
  new = 1,
  assigned = 2,
  arranged = 3,
  inDelivery = 4,
  done = 5,
}
