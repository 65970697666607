import { Box, Button, InputAdornment, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';
import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Vehicle } from '../../../models/vehicles/Vehicle';
import { FormTextField } from '../../common/input/FormTextField';
import { FormCheckboxWithLabel } from '../../common/input/FormCheckboxWithLabel';

export interface VehicleValues {
  id: number;
  brand: string;
  model: string;
  licensePlate: string;
  loadWeight: number;
  active: boolean;
}

interface Props {
  data: Vehicle;
  onClose: () => void;
  onSave: (values: VehicleValues) => void;
}

export const EditVehicle: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const initialValues: VehicleValues = {
    id: data.id,
    brand: data.brand,
    model: data.model,
    licensePlate: data.licensePlate,
    loadWeight: data.loadWeight,
    active: data.active,
  };

  const handleSubmit = (value: VehicleValues): void => onSave(value);
  const validationSchema = Yup.object({
    brand: Yup.string().required('Kérem adja meg a jármű márkáját'),
    model: Yup.string().required('Kérem adja meg a jármű modelljét'),
    licensePlate: Yup.string().required('Kérem adja meg a rendszámot'),
    loadWeight: Yup.number()
      .min(1, 'Kérem adja meg a jármű terhelhetőségét')
      .required('Kérem adja meg a jármű terhelhetőségét'),
  });

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{data.id > 0 ? 'Szerkesztés' : 'Létrehozás'}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <>
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  <Box pb={2}>
                    <FormTextField size="small" label="Márka" name="brand" id="brand" fullWidth />
                  </Box>

                  <Box pb={2}>
                    <FormTextField size="small" label="Modell" name="model" id="model" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField size="small" label="Rendszám" name="licensePlate" id="licensePlate" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField
                      size="small"
                      label="Terhelhetőség"
                      name="loadWeight"
                      id="loadWeight"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                      }}
                    />
                  </Box>

                  <Box>
                    <FormCheckboxWithLabel label="Aktív" name="active" />
                  </Box>

                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
