import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppAccount } from '../../models/Account';

import { Box, Button, Typography } from '@mui/material';

//import { LayoutReducerActions } from "modules/office/actions/LayoutReducer";
import { PleaseWait } from './PleaseWait';
import { AppRoute } from '../../router/AppRoutes';
import { securityClient } from '../../security/securityClient';
import { setUserProfile } from '../../slices/userSlice';
import { User } from '../../models/users/User';
import { redirect } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  routes: AppRoute[];
  activeRoute: AppRoute | undefined;
};

export const AuthenticationGuard = ({ children, routes, activeRoute }: Props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoginValid, setIsLoginValid] = useState(false);

  const isBlocked = false;
  const authClient = securityClient;

  useEffect(() => {
    authClient
      .initialize()
      .then((appAccount: AppAccount | undefined | 'unauthorized') => {
        if (appAccount === undefined) {
          authClient.redirectSignIn();

          return;
        }

        const isIn = appAccount === 'unauthorized' ? undefined : appAccount != null;

        if (isIn === undefined) {
          authClient.signOut();
          return;
        }
        const account = appAccount as AppAccount;
        const user: User = {
          id: account.id,
          firstName: account.firstName,
          lastName: account.lastName,
          active: true,
          email: account.email,
          roleId: account.roleId,
          phone: account.phone,
        };
        dispatch(
          setUserProfile({
            ...user,
          })
        );

        // dispatch(
        //   LayoutReducerActions.setNames(account.firstName, account.lastName)
        // );
        setIsLoading(false);
        setIsLoginValid(true);
      })
      .catch(() => {
        authClient.redirectSignIn();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAccess = !activeRoute || (routes && routes.find((route) => route.path === activeRoute.path));

  return (
    <>
      {(isLoading || isBlocked) && <PleaseWait />}
      {!hasAccess && !isLoading && !isBlocked && (
        <Box
          padding={3}
          marginTop={20}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h6" component={Box} paddingBottom={2}>
            Nincs hozzáférése ehhez az oldalhoz.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              redirect('/');
            }}
          >
            Vissza a kezdőlapra
          </Button>
        </Box>
      )}
      {!isLoading && isLoginValid && !isBlocked && hasAccess && <>{children}</>}
    </>
  );
};
