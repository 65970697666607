import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { VehiclesState } from '../models/slices/VehiclesState';
import { LoadVehiclesResponse } from '../models/vehicles/LoadVehiclesResponse';
import { SaveVehicleResponse } from '../models/vehicles/SaveVehicleResponse';
import { Vehicle } from '../models/vehicles/Vehicle';

const initialState: VehiclesState = {
  vehicles: undefined,
  vehicle: undefined,
};

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadVehiclesResponse | undefined>) => {
      state.vehicles = action.payload?.vehicles;
    },
    setVehicles: (state, action: PayloadAction<Vehicle[] | null | undefined>) => {
      state.vehicles = action.payload;
    },
    updateState: (state, action: PayloadAction<SaveVehicleResponse>) => {
      if (action.payload.vehicle) {
        state.vehicle = action.payload.vehicle;
      }
    },
  },
});

export const { setResponse, updateState, setVehicles } = vehiclesSlice.actions;

export const selectVehicles = (state: RootState) => state.localData.vehicles.vehicles;

export default vehiclesSlice.reducer;
