import { SignInResponse } from '../models/SignInResponse';
import { apiClient } from './apiClient';
import { IAccess } from './IAccess';
import { SignInRequest } from '../models/SignInRequest';
import { ValidateUser } from '../models/ValidateUser';
import { SaveAccountRequest } from '../models/account/SaveAccountRequest';
import { SaveAccountResponse } from '../models/account/SaveAccountResponse';
import { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import { ForgotPasswordResponse } from '../models/ForgotPasswordResponse';
import { LoadUsersRequest } from '../models/users/LoadUsersRequest';
import { LoadUsersResponse } from '../models/users/LoadUsersResponse';
import { SaveUserResponse } from '../models/users/SaveUserResponse';
import { SaveUserRequest } from '../models/users/SaveUserRequest';
import { LoadVehiclesRequest } from '../models/vehicles/LoadVehiclesRequest';
import { LoadVehiclesResponse } from '../models/vehicles/LoadVehiclesResponse';
import { SaveVehicleRequest } from '../models/vehicles/SaveVehicleRequest';
import { SaveVehicleResponse } from '../models/vehicles/SaveVehicleResponse';

import { LoadOrdersRequest } from '../models/orders/LoadOrdersRequest';
import { LoadOrdersResponse } from '../models/orders/LoadOrdersResponse';
import { SaveOrderRequest } from '../models/orders/SaveOrderRequest';
import { SaveOrderResponse } from '../models/orders/SaveOrderResponse';
import { LoadDeliveriesRequest } from '../models/delivery/LoadDeliveriesRequest';
import { LoadDeliveriesResponse } from '../models/delivery/LoadDeliveriesResponse';
import { DeliveryStatus } from '../models/delivery/Delivery';
import { SaveDeliveryRequest } from '../models/delivery/SaveDeliveryRequest';
import { SaveDeliveryResponse } from '../models/delivery/SaveDeliveryResponse';

export class Access implements IAccess {
  async signIn(criteria: SignInRequest): Promise<SignInResponse | undefined> {
    const promise: Promise<SignInResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/signIn.php', criteria);
          const response: SignInResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async validateUser(): Promise<ValidateUser | undefined> {
    const promise: Promise<ValidateUser | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/validateUser.php');
          const response: ValidateUser | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveAccount(criteria: SaveAccountRequest): Promise<SaveAccountResponse | undefined> {
    const promise: Promise<SaveAccountResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/save.php', criteria);
          const response: SaveAccountResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async forgotPassword(criteria: ForgotPasswordRequest): Promise<ForgotPasswordResponse | undefined> {
    const promise: Promise<ForgotPasswordResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/forgotPassword.php', criteria);
          const response: ForgotPasswordResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadUsers(criteria: LoadUsersRequest): Promise<LoadUsersResponse | undefined> {
    const promise: Promise<LoadUsersResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/load.php', criteria);
          const response: LoadUsersResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveUser(criteria: SaveUserRequest): Promise<SaveUserResponse | undefined> {
    const promise: Promise<SaveUserResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/save.php', criteria);
          const response: SaveUserResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadVehicles(criteria: LoadVehiclesRequest): Promise<LoadVehiclesResponse | undefined> {
    const promise: Promise<LoadVehiclesResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/vehicles/load.php', criteria);
          const response: LoadVehiclesResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveVehicle(criteria: SaveVehicleRequest): Promise<SaveVehicleResponse | undefined> {
    const promise: Promise<SaveVehicleResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/vehicles/save.php', criteria);
          const response: SaveVehicleResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadOrders(criteria: LoadOrdersRequest): Promise<LoadOrdersResponse | undefined> {
    const promise: Promise<LoadOrdersResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/orders/load.php', criteria);
          const response: LoadOrdersResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveOrder(criteria: SaveOrderRequest): Promise<SaveOrderResponse | undefined> {
    const promise: Promise<SaveOrderResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/orders/save.php', criteria);
          const response: SaveOrderResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadDeliveries(criteria: LoadDeliveriesRequest): Promise<LoadDeliveriesResponse | undefined> {
    const promise: Promise<LoadDeliveriesResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/deliveries/load.php', criteria);
          const response: LoadDeliveriesResponse | undefined = result.data;
          // const response: LoadDeliveriesResponse = {
          //   activeDeliveries: [
          //     {
          //       id: 1,
          //       date: new Date('2025-01-01'),
          //       driver: {
          //         id: 1,
          //         name: 'Kiss Joska',
          //       },
          //       loadWeight: 200,
          //       orders: [{ id: 1}],
          //       status: DeliveryStatus.assigned,
          //       vehicle: {
          //         id: 1,
          //         active: true,
          //         brand: 'Iveco',
          //         licensePlate: 'ABC 123',
          //         loadWeight: 2500,
          //         model: 'Daily',
          //       },
          //     },
          //   ],
          // };
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveDelivery(criteria: SaveDeliveryRequest): Promise<SaveDeliveryResponse | undefined> {
    const promise: Promise<SaveDeliveryResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/deliveries/save.php', criteria);
          const response: SaveDeliveryResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }
}
