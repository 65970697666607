import React, { ReactElement, useMemo } from 'react';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, SxProps, TextField, Theme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSessionStorage } from '../../utility/useSessionStorage';

export interface Pagination {
  page: number;
  rowsPerPage: number;
  order?: 'asc' | 'desc';
  orderBy?: string;
  searchTerm?: string;
}

export interface TableRowData {
  id: string | number;
  rowId: string | number;
  extraId?: string | number | undefined;
  plusId?: string | number | undefined;
  columns: any[];
  data?: any;
  sx?: SxProps<Theme>;
}

interface TableComponentProps {
  id: string;
  title?: string;
  headCells: TableHeaderCell[];
  rows: TableRowData[];
  selected: TableRowData | undefined;
  setSelected: (selected: TableRowData | undefined) => void;
  hideOthersOnSelect?: boolean;
  hidePaper?: boolean;
  onSelect?: (selected: TableRowData) => void;
  storeState?: boolean;
  defaultPagination?: Pagination;
  filterByColumnId?: string[];
}
interface TableToolbarProps {
  title?: string;
}

export interface TableHeaderCell {
  id: string;
  isNumeric: boolean;
  disablePadding: boolean;
  disableOrdering?: boolean;
  label: string | ReactElement;
  width?: string;
  disableSorting?: boolean;
}

export interface TableHeaderProps {
  order: 'asc' | 'desc' | undefined;
  orderBy: any | undefined;
  onRequestSort: any;
  headCells: TableHeaderCell[];
}

function descendingComparator(a: any, b: any, orderBy: any) {
  const aColumn = a.columns.find((c: any) => c.id === orderBy);
  const bColumn = b.columns.find((c: any) => c.id === orderBy);
  const aValue = aColumn?.cValue ?? aColumn?.value;
  const bValue = bColumn?.cValue ?? bColumn?.value;
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
}: TableHeaderProps) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: TableHeaderCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.isNumeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width ?? 'auto' }}
          >
            <TableSortLabel
              disabled={headCell.disableOrdering === true}
              active={orderBy === headCell.id && order !== undefined}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && order !== undefined ? (
                <Box
                  component="span"
                  sx={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TableToolbar: React.FC<TableToolbarProps> = ({ title }: TableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: 2, pr: 1 }}>
      {title && (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

export const Table: React.FC<TableComponentProps> = ({
  id,
  title,
  headCells,
  rows,
  selected,
  setSelected,
  hideOthersOnSelect,
  storeState,
  hidePaper,
  onSelect,
  defaultPagination,
  filterByColumnId,
}: TableComponentProps) => {
  const { value: pagination, setValue: setPagination } = useSessionStorage<Pagination>(
    `table${id}`,
    storeState !== true,
    {
      page: defaultPagination?.page ?? 0,
      rowsPerPage: defaultPagination?.rowsPerPage ?? 5,
      order: defaultPagination?.order,
      orderBy: defaultPagination?.orderBy,
      searchTerm: '',
    }
  );
  const { page, order, orderBy, rowsPerPage, searchTerm } = pagination;

  const getNewOrder = (
    currentOrderBy: string,
    currentOrder: 'asc' | 'desc' | undefined
  ): 'asc' | 'desc' | undefined => {
    if (currentOrder === undefined) return 'asc';

    const isDesc = orderBy === currentOrderBy && currentOrder === 'desc';
    const isAsc = orderBy === currentOrderBy && currentOrder === 'asc';

    if (isDesc && defaultPagination?.order === undefined) return undefined;

    return isAsc ? 'desc' : 'asc';
  };

  const handleRequestSort = (event: any, property: any) => {
    const newOrder = getNewOrder(property, order);
    setPagination({ ...pagination, order: newOrder, orderBy: property });
  };

  const handleClick = (event: any, row: TableRowData | undefined) => {
    setSelected(row?.id === selected?.id ? undefined : row);
    if (onSelect && row != null) onSelect(row);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeSearchTerm = (event: any) => {
    const value = event.target.value;
    setPagination({ ...pagination, searchTerm: value });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const isSelected = (row: TableRowData) => {
    return row.id === selected?.id;
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterByTerm = (item: TableRowData): boolean => {
    if (searchTerm && filterByColumnId != null && filterByColumnId.length > 0) {
      const st = searchTerm.toLowerCase();
      let index = 0;
      let found = false;
      do {
        const columnId = filterByColumnId[index];
        const column = item.columns.find((c) => c.id === columnId);
        if (column) {
          let value = column?.cValue ?? column?.value;
          if (value) {
            value = value.toString().toLowerCase();
            const indexOf = value?.indexOf(st);
            found = indexOf != null && indexOf > -1;
          }
        }
        index++;
      } while (!found && index < filterByColumnId.length);

      return found;
    }

    return true;
  };

  const allItems = useMemo(() => {
    let rowItems = order && orderBy ? stableSort(rows, getComparator(order, orderBy)) : rows;

    if (searchTerm) {
      rowItems = rowItems.filter(filterByTerm);
    }

    return rowItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, order, orderBy, searchTerm]);

  const items = useMemo(() => {
    const rowItems = order && orderBy ? stableSort(allItems, getComparator(order, orderBy)) : allItems;
    if (defaultPagination == null) return rowItems;
    const currentPage = searchTerm ? 0 : page;
    return rowItems.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, page, rowsPerPage, searchTerm]);

  const table = (
    <>
      {title && <TableToolbar title={title} />}
      {filterByColumnId != null && filterByColumnId.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <TextField
            id={`search-term-${id}`}
            label="Szűrés"
            variant="standard"
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            size="small"
          />
        </Box>
      )}
      <TableContainer id={id}>
        <MuiTable aria-labelledby="tableTitle" size="small" aria-label={`${title} table`}>
          <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {items.map((row: TableRowData, index: number) => {
              const isItemSelected = isSelected(row);

              return !hideOthersOnSelect || (hideOthersOnSelect === true && (!selected || isItemSelected)) ? (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row)}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.rowId}
                  selected={isItemSelected}
                  sx={row.sx}
                >
                  {row.columns.map((col: any, colIndex: number) => {
                    return (
                      <TableCell
                        align={headCells[colIndex].isNumeric ? 'right' : 'left'}
                        key={`${row.rowId}_${uuidv4()}`}
                        sx={{ pl: 0, pr: 1, width: headCells[colIndex].width ?? 'auto' }}
                      >
                        {col.value}
                      </TableCell>
                    );
                  })}
                  {/* <TableCell component="th" scope="row" padding="normal">
                    {row.name}
                  </TableCell> */}
                </TableRow>
              ) : null;
            })}
            {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {defaultPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={allItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Oldalanként"
          labelDisplayedRows={({ from, to, count }) => (
            <Typography
              component="span"
              variant="body2"
              sx={
                from > 1 || searchTerm
                  ? (theme: Theme) => ({
                      backgroundColor: theme.palette.primary.light,
                    })
                  : undefined
              }
            >
              {count > 1 ? `${from}..${to}${count !== -1 ? ` / ${count}` : ''}` : ''}
            </Typography>
          )}
        />
      )}
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {hidePaper ? table : <Paper sx={{ width: '100%', marginBottom: 2 }}>{table}</Paper>}
    </Box>
  );
};
