import { OrderType } from '../../../models/orders/Order';

interface Props {
  data: OrderType;
}

export const Type: React.FC<Props> = ({ data }: Props) => {
  if (data === OrderType.shitb) {
    return <img src="/shitb.png" style={{ width: 83, height: 36 }} alt="" />;
  }
  if (data === OrderType.higiguru) {
    return <img src="/higiguru.jpg" style={{ width: 83, height: 36 }} alt="" />;
  }
  return null;
};
