import { Box, /*Button, InputAdornment, LinearProgress,*/ SwipeableDrawer, Typography } from '@mui/material';
//import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Order } from '../../../models/orders/Order';
// import { FormTextField } from '../../common/input/FormTextField';
// import { FormCheckboxWithLabel } from '../../common/input/FormCheckboxWithLabel';
import { Client } from '../../../models/orders/Client';
import { OrderItem } from '../../../models/orders/OrderItem';

export interface OrderValues {
  id: number;
  number: string;
  client: Client;
  type: number;
  status: number;
  orderDate: Date;
  deliveryDate?: Date;
  comments: string;
  items: OrderItem[];
}

interface Props {
  data: Order;
  onClose: () => void;
  onSave: (values: OrderValues) => void;
}

export const EditOrder: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  // const initialValues = {};

  const handleSubmit = (value: OrderValues): void => onSave(value);
  const validationSchema = Yup.object({
    // brand: Yup.string().required('Kérem adja meg a jármű márkáját'),
    // model: Yup.string().required('Kérem adja meg a jármű modelljét'),
    // licensePlate: Yup.string().required('Kérem adja meg a rendszámot'),
    // loadWeight: Yup.number()
    //   .min(1, 'Kérem adja meg a jármű terhelhetőségét')
    //   .required('Kérem adja meg a jármű terhelhetőségét'),
  });

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{data.id > 0 ? 'Szerkesztés' : 'Létrehozás'}</Typography>
        <Box sx={{ pt: 4 }}>
          {/* <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <>
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  <Box pb={2}>
                    <FormTextField size="small" label="Márka" name="brand" id="brand" fullWidth />
                  </Box>

                  <Box pb={2}>
                    <FormTextField size="small" label="Modell" name="model" id="model" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField size="small" label="Rendszám" name="licensePlate" id="licensePlate" fullWidth />
                  </Box>
                  <Box pb={2}>
                    <FormTextField
                      size="small"
                      label="Terhelhetőség"
                      name="loadWeight"
                      id="loadWeight"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                      }}
                    />
                  </Box>

                  <Box>
                    <FormCheckboxWithLabel label="Aktív" name="active" />
                  </Box>

                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik> */}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
