import { Box, Button, Checkbox, SwipeableDrawer, Typography } from '@mui/material';
import { Delivery } from '../../../models/delivery/Delivery';
import { useEffect, useMemo, useState } from 'react';
import { Access } from '../../../access/Access';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import { Order, OrderStatus } from '../../../models/orders/Order';
import { LoadOrdersResponse } from '../../../models/orders/LoadOrdersResponse';
import { useFormat } from '../../../utility/useFormat';
import { Table, TableHeaderCell } from '../../common/Table';
import { getTypeName } from '../../../utility/orders';
import ListIcon from '@mui/icons-material/List';

interface Props {
  data: Delivery;
  onClose: () => void;
  onSave: (deliveryId: number, selectedOrders: Order[]) => void;
  onSelectOrder: (order: Order | undefined) => void;
}

export const AssignOrder: React.FC<Props> = ({ data, onClose, onSave, onSelectOrder }: Props) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<Order[] | undefined | null>(undefined);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>(data.orders);
  const { formatDate } = useFormat();

  const loadOrders = (): void => {
    const access = new Access();
    access
      .loadOrders({ status: [OrderStatus.new, OrderStatus.arranged] })
      .then((value: LoadOrdersResponse | undefined) => {
        if (value?.error == null) {
          setOrders(value?.orders);
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      });
  };

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'select',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'number',
      isNumeric: false,
      label: 'Szám / dátum',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'type',
      isNumeric: false,
      label: 'Cég',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'client',
      isNumeric: false,
      label: 'Vásárló',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '25%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const handleSelectOrder = (checked: boolean, order: Order): void => {
    if (checked) {
      const orders = [...selectedOrders, order];
      setSelectedOrders(orders);
    } else {
      const orders = selectedOrders.filter((o: Order) => o.id !== order.id);
      setSelectedOrders(orders);
    }
  };

  const ordersData = useMemo(() => {
    const items = [...(orders ?? []), ...data.orders];

    return items.map((order: Order) => ({
      rowId: `assign-order-${order.id}`,
      id: order.id,
      data: order,
      columns: [
        {
          id: 'select',
          cValue: order.id,
          value: (
            <>
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={selectedOrders.some((o) => o.id === order.id)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  handleSelectOrder(checked, order)
                }
                size="small"
              />
            </>
          ),
        },
        {
          id: 'number',
          cValue: order.number,
          value: (
            <>
              {order.number} / {formatDate(order.orderDate)}
            </>
          ),
        },
        {
          id: 'type',
          cValue: order.type,
          value: getTypeName(order.type),
        },
        {
          id: 'client',
          cValue: order.client.name,
          value: order.client.name,
        },
        {
          id: 'address',
          cValue: order.client.address,
          value: <>{order.client.address}</>,
        },
        {
          id: 'operation',
          cValue: '',
          value: (
            <>
              <Button startIcon={<ListIcon />} color="primary" size="small" onClick={() => onSelectOrder(order)}>
                Infó
              </Button>
            </>
          ),
        },
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, data.orders, selectedOrders]);

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 700 }, p: 3 }}>
        <Typography variant="h5">Rendelések kezelése</Typography>
        <Box sx={{ pt: 4 }}>
          <Table
            id="assign-orders"
            headCells={headCells}
            rows={ordersData}
            hideOthersOnSelect={true}
            selected={undefined}
            setSelected={() => {}}
            hidePaper
            defaultPagination={{ page: 0, rowsPerPage: 25, order: 'desc', orderBy: 'number' }}
          />
        </Box>
        <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Mégse
          </Button>
          <Button variant="contained" onClick={() => onSave(data.id, selectedOrders)}>
            Mentés
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
